
import AuthMixin from '@/mixins/AuthMixin';
import { handleErrorUI, handleSuccessUI } from '../../util/error';

export default {
  mixins: [AuthMixin],
  data() {
    return {
      email: '',
      domain: '',
      i18n: {
        accountLimit: 'Account Limit',
        backToHome: 'Back to Home',
        notifyAdmin: 'Notify Administrator'
      },
      messageTemplate:
        'This free company account has run out of free licenses. <b>Notify account administrator</b> to grant your access request.'
    };
  },
  mounted() {
    const { email, domain } = this.$route.query;
    if (!email || !domain) {
      this.$router.push({ name: 'auth:signup' });
      return;
    }
    this.email = email as string;
    this.domain = domain as string;
  },
  methods: {
    async notifyAdmin() {
      try {
        const res = await this.$repo.auth.requestJoinToAdmin(
          this.domain,
          this.email
        );
        handleSuccessUI('Join request sent to account administrator');
      } catch (err) {
        handleErrorUI(err);
      }
    }
  }
};
